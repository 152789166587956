<template>
  <div class="container">
    <com-header />
    <div class="main">
      <div class="jobName">
        {{
          lang.startsWith("zh")
            ? job.jobName
            : job.jobNameEn
            ? job.jobNameEn
            : job.jobName
        }}
        <div class="jobInfo jobBase">
          <span>{{
            lang.startsWith("zh")
              ? job.jobBase
              : job.jobBaseEn
              ? job.jobBaseEn
              : job.jobBase
          }}</span>
        </div>
      </div>

      <div class="jobInfo">
        <span
          >{{ $t("detail_team") }}:{{
            lang.startsWith("zh")
              ? job.department
              : job.departmentEn
              ? job.departmentEn
              : job.department
          }}</span
        ><span
          >{{ $t("detail_business") }}:{{
            lang.startsWith("zh")
              ? job.lineOfBusiness
              : job.lineOfBusinessEn
              ? job.lineOfBusinessEn
              : job.lineOfBusiness
          }}</span
        ><span
          >{{ $t("detail_level") }}:{{
            lang.startsWith("zh")
              ? job.jobLevel
              : job.jobLevelEn
              ? job.jobLevelEn
              : job.jobLevel
          }}</span
        >
      </div>
      <div class="jobInfo" style="margin-bottom: 38px">
        <span
          >{{ $t("detail_salary") }}:{{
            lang.startsWith("zh")
              ? job.monthlySalary
              : job.monthlySalaryEn
              ? job.monthlySalaryEn
              : job.monthlySalary
          }}</span
        ><span
          >{{ $t("home_incentive") }}:{{
            lang.startsWith("zh")
              ? job.incentiveAmount
              : job.incentiveAmountEn
              ? job.incentiveAmountEn
              : job.incentiveAmount
          }}</span
        >
      </div>
      <div class="jobDetail">
        <div class="title">{{ $t("detail_duty") }}</div>
        <div v-if="lang === 'zh_CN'" class="detail">
          <div class="item" v-for="(duty, index) in job.jobDuty" :key="index">
            <span></span>
            <i v-html="duty.replaceAll('\r\n', '<br>')"></i>
          </div>
        </div>
        <div v-else class="detail">
          <div class="item" v-for="(duty, index) in job.jobDutyEn" :key="index">
            <span></span>
            <i v-html="duty.replaceAll('\r\n', '<br>')"></i>
          </div>
        </div>
      </div>

      <!-- <div class="jobDetail">
        <div class="title">工作要求</div>
        <div class="detail">
          <div
            class="item"
            v-for="(requirement, index) in job.jobRequirement"
            :key="index"
          >
            <span></span>{{ requirement }}
          </div>
        </div>
      </div> -->
      <div class="checkDetail" @click="dialog1 = true">
        {{ $t("detail_rule") }}
        <img style="width: 14px" src="../assets/img/right.png" alt="" />
      </div>
      <span class="recommend" @click="dialog2 = true"
        ><img src="../assets/img/good.png" />{{ $t("detail_do_it") }}</span
      >
    </div>
    <recommend-modal :visible="dialog2" @changeModalVisible='(data)=> dialog2 = data'/>
    <el-dialog :title="detail_rule_title" width="80%" :visible.sync="dialog1">
      <div class="dialog">
        <div class="dialogMain">
          <!-- <div class="rule" @click="$router.push('/rule')">
            {{ $t("detail_check_rule") }}
            <img style="width: 14px" src="../assets/img/right.png" alt="" />
          </div> -->
          <div class="dialogTitle">
            <span></span> {{ $t("detail_rule_message_1") }}
          </div>
          <div class="discripe">
            {{ $t("detail_rule_message_2") }}
          </div>
          <div class="dialogTitle">
            <span></span> {{ $t("detail_rule_message_3") }}
          </div>
          <div class="discripe">
            <div>
              {{ $t("detail_rule_message_4") }}
            </div>
            <div class="table">
              <div class="tableHeader">
                <span>{{ $t("detail_rule_message_5") }}</span>
                <span>{{ $t("detail_rule_message_6") }}</span>
              </div>
              <div class="item">
                <span class="left">{{ $t("detail_rule_message_7") }}</span>
                <span class="right">{{ $t("detail_rule_message_8") }}</span>
              </div>
              <div class="item">
                <span class="left">{{ $t("detail_rule_message_9") }}</span>
                <span class="right">{{ $t("detail_rule_message_10") }}</span>
              </div>
              <div class="item">
                <span class="left">{{ $t("detail_rule_message_11") }}</span>
                <span class="right">{{ $t("detail_rule_message_12") }}</span>
              </div>
              <div class="item">
                <span class="left">{{ $t("detail_rule_message_25") }}</span>
                <span class="right">{{ $t("detail_rule_message_26") }}</span>
              </div>
              <div class="item">
                <span class="left">{{ $t("detail_rule_message_27") }}</span>
                <span class="right">{{ $t("detail_rule_message_28") }}</span>
              </div>
            </div>
          </div>
          <div class="dialogTitle">
            <span></span> {{ $t("detail_rule_message_13") }}
          </div>
          <div class="discripe">
            <div>
              {{ $t("detail_rule_message_14") }}
            </div>
            <div>{{ $t("detail_rule_message_15") }}</div>
            <div>{{ $t("detail_rule_message_16") }}</div>
            <div>{{ $t("detail_rule_message_17") }}</div>
          </div>
          <div class="dialogTitle">
            <span></span> {{ $t("detail_rule_message_18") }}
          </div>
          <div class="discripe">
            <div>{{ $t("detail_rule_message_19") }}</div>
            <div>{{ $t("detail_rule_message_20") }}</div>
            <div>{{ $t("detail_rule_message_23") }}</div>
            <div>{{ $t("detail_rule_message_24") }}</div>
          </div>
          <div class="dialogTitle">
            <span></span> {{ $t("detail_rule_message_21") }}
          </div>
          <div class="discripe">
            <div>{{ $t("detail_rule_message_22") }}</div>
          </div>
        </div>
        <div class="footer">
          <div class="button" @click="dialog1 = false">
            {{ $t("detail_know") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findJobById } from "@/api";
import { get as getLanguage } from "@/utils/config.js";
import header from "@/components/header.vue";
import recommendModal from '@/components/recommendModal.vue'
export default {
  data() {
    return {
      job: {},
      dialog1: false,
      dialog2: false,
      lang: getLanguage(),
      detail_rule_title: this.$t("detail_rule_title"),
    };
  },
  methods: {
    getJob() {
      const { query } = this.$route;
      if (query.id) {
        findJobById({ id: query.id }).then((res) => {
          this.job = res.data;
          console.log(this.job);
          if (!this.job.jobDutyEn) {
            this.job.jobDutyEn = this.job.jobDuty;
          }
        });
      }
    },
    onCopy() {
      this.$message.success(this.$t("detail_copy"));
    },
    onError() {
      this.$message.error(this.$t("detail_error"));
    },
  },
  components: {
    ComHeader: header,
    recommendModal: recommendModal
  },
  mounted() {
    this.getJob();
  },
};
</script>

<style lang="less" scoped>
.container {
  background-image: url(../assets/img/home-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 8% 120px 82px;
  .main {
    padding: 40px;
    position: relative;
    background: #ffffff;
    border-radius: 16px;
    .jobName {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #151617;
      display: flex;
      align-items: center;
    }
    .jobBase {
      background-image: url(../assets/img/base.png);
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 2px center;
      padding-left: 18px;
      margin-left: 14px;
    }
    .jobInfo {
      font-size: 14px;
      line-height: 24px;
      color: #97a1a9;
      span {
        margin-right: 20px;
      }
    }
    .jobDetail {
      margin-bottom: 24px;
      .title {
        font-size: 14px;
        line-height: 20px;
        color: #555b63;
        margin-bottom: 8px;
      }
      .detail {
        background: #f7f8f9;
        border-radius: 8px;
        padding: 20px;
        .item {
          font-size: 16px;
          line-height: 32px;
          color: #151617;
          display: flex;
          align-items: center;

          i {
            font-style: normal;
            margin-left: 10px;
          }
          span {
            display: inline-block;
            margin-right: 8px;
            width: 6px;
            height: 6px;
            flex: none;
            background: #a4adb3;
            transform: rotateZ(45deg);
          }
        }
      }
    }
    .checkDetail {
      font-size: 16px;
      line-height: 22px;
      color: #2ebc84;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .recommend {
      width: 300px;
      height: 40px;
      background: #2ebc84;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
}
.dialog {
  .footer {
    border-top: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    .button {
      background: #16b979;
      border-radius: 2px;
      cursor: pointer;
      width: 360px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      color: #ffffff;
    }
  }
}
.dialogMain {
  padding: 20px;
  .recommendTitle {
    margin: 10px 0;
  }
  .rule {
    color: #16b979;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .emailTitle {
    font-size: 14px;
    line-height: 22px;
    color: #151617;
    margin-bottom: 4px;
  }
  .format {
    .email {
      font-size: 16px;
      line-height: 24px;
      color: #2ebc84;
      margin-bottom: 12px;
      span {
        cursor: pointer;
      }
    }
    background: #f7f8f9;
    border-radius: 4px;
    padding: 8px 12px;
    div {
      font-size: 12px;
      line-height: 20px;
      color: #151617;
    }
  }
  .dialogTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: #151617;
    margin-bottom: 4px;
    span {
      display: inline-block;
      margin-right: 8px;
      width: 6px;
      height: 6px;
      background: #2ebc84;
      transform: rotateZ(45deg);
    }
  }
  .discripe {
    font-size: 14px;
    line-height: 22px;
    color: #151617;
    margin-bottom: 20px;
    .table {
      margin-top: 12px;
      border: 1px solid #eaecef;
      border-radius: 4px;
      .tableHeader {
        background: #f7f8f9;
        display: flex;
        height: 40px;
        span {
          flex: 1;
          text-align: center;
          font-size: 14px;
          line-height: 40px;
          color: #97a1a9;
        }
      }
      .item {
        display: flex;
        height: 40px;
        border-bottom: 1px solid #f4f5f7;
        &:last-child {
          border-bottom: none;
        }
        span {
          flex: 1;
          line-height: 40px;
          text-align: center;
        }
        .left {
          border-right: 1px solid #f4f5f7;
        }
        .right {
          color: #2ebc84;
        }
      }
    }
  }
}
@media screen and (max-width: 765px) {
  .container {
    padding: 17% 20px 20px;

    .main {
      padding: 20px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .container {
    padding: 17% 20px 20px;

    .main {
      margin-top: 20px;
      padding: 20px;
    }
  }
}
</style>