<template>
  <div class="recommendModalWarp">
    <el-dialog
      :title="detail_rule_title"
      width="80%"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <div class="dialog">
        <div class="dialogMain">
          <div class="recommendTitle">
            {{ $t("detail_recommend_title_in") }}
          </div>
          <div class="format">
            <div>{{ $t("detail_recommend_in_function") }}</div>
          </div>
          <div class="recommendTitle">
            {{ $t("detail_recommend_title_out") }}
          </div>
          <div class="format">
            <div class="emailTitle">{{ $t("detail_email") }}</div>
            <div class="email">
              <span
                v-clipboard:copy="'renrenbole01@gmail.com'"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                >renrenbole01@gmail.com</span
              >
            </div>
            <div>{{ $t("detail_email_format") }}</div>
            <div>{{ $t("detail_recommend_format") }}</div>
          </div>
          <div class="recommendTitle">
            {{ $t("detail_recommend_extend_1") }}
          </div>
           <div class="format">
            <div>{{ $t("detail_recommend_extend_2") }}</div>
            <br/>
            <div>
              {{$t("detail_recommend_extend_3")}}
            </div>
            <br/>
            <div>
              {{$t("detail_recommend_extend_4")}}
              <ul>
              <li>·{{$t("detail_recommend_extend_5")}}</li>
              <li>·{{$t("detail_recommend_extend_6")}}</li>
              </ul>
            </div>
            <br/>
            <div>
              {{$t("detail_recommend_extend_7")}}
            </div>
            <br/>
            <div>{{$t("detail_recommend_extend_8")}}</div>
          </div>
        </div>
        <div class="footer">
          <div class="button" @click="handleClose">
            {{ $t("detail_know") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { get as getLanguage } from "@/utils/config.js";

export default {
  props: ["visible"],
  data() {
    return {
      lang: getLanguage(),
      detail_rule_title: this.$t("detail_rule_title"),
    };
  },
  methods: {
    onCopy() {
      this.$message.success(this.$t("detail_copy"));
    },
    onError() {
      this.$message.error(this.$t("detail_error"));
    },
    /** 关闭 */
    handleClose() {
      this.$emit("changeModalVisible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.recommendModalWarp {
  .dialog {
    .footer {
      border-top: 1px solid #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      .button {
        background: #16b979;
        border-radius: 2px;
        cursor: pointer;
        width: 360px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        color: #ffffff;
      }
    }
  }
  .dialogMain {
    padding: 20px;

    .recommendTitle {
      margin: 10px 0;
    }
    .rule {
      color: #16b979;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .emailTitle {
      font-size: 14px;
      line-height: 22px;
      color: #151617;
      margin-bottom: 4px;
    }
    .format {
      background: #f7f8f9;
      border-radius: 4px;
      padding: 8px 12px;
      .email {
        font-size: 16px;
        line-height: 24px;
        color: #2ebc84;
        margin-bottom: 12px;
        span {
          cursor: pointer;
        }
      }
      div {
        font-size: 12px;
        line-height: 20px;
        color: #151617;
      }
    }
    .dialogTitle {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: #151617;
      margin-bottom: 4px;
      span {
        display: inline-block;
        margin-right: 8px;
        width: 6px;
        height: 6px;
        background: #2ebc84;
        transform: rotateZ(45deg);
      }
    }
    .discripe {
      font-size: 14px;
      line-height: 22px;
      color: #151617;
      margin-bottom: 20px;
      .table {
        margin-top: 12px;
        border: 1px solid #eaecef;
        border-radius: 4px;
        .tableHeader {
          background: #f7f8f9;
          display: flex;
          height: 40px;
          span {
            flex: 1;
            text-align: center;
            font-size: 14px;
            line-height: 40px;
            color: #97a1a9;
          }
        }
        .item {
          display: flex;
          height: 40px;
          border-bottom: 1px solid #f4f5f7;
          &:last-child {
            border-bottom: none;
          }
          span {
            flex: 1;
            line-height: 40px;
            text-align: center;
          }
          .left {
            border-right: 1px solid #f4f5f7;
          }
          .right {
            color: #2ebc84;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .container {
    padding: 17% 20px 20px;

    .main {
      padding: 20px;
    }
  }
}
</style>